import React, { lazy, Suspense } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingScreen from '../components/LoadingScreen';
import withErrorHandler from '../containers/GlobalHandlingProvider/components/errorHandler';

const Loadable = Component => props => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

export const ReportsWrapper = ({ children }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div
      style={{
        padding: largeScreen ? '25px 20px' : '0px',
        background: 'rgb(250, 253, 255)',
      }}
    >
      {children}
    </div>
  );
};

export const SignInPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/SignIn'))),
);
export const SignUpPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/SignUp'))),
);
export const InvestmentsPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Investments'))),
);
export const ReportsPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Reports'))),
);
export const AnalysisPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Analysis'))),
);
export const AnalysisListPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/AnalysisList'))),
);
export const AccountPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Account'))),
);
export const Page404 = Loadable(lazy(() => import('../pages/404')));

export const ClientsListPage = Loadable(
  lazy(() => import('../pages/ClientsList')),
);
export const ClientsNewPage = Loadable(
  lazy(() => import('../pages/ClientsNew')),
);
export const ClientsEditPage = Loadable(
  lazy(() => import('../pages/ClientsEdit')),
);

export const PropertiesListPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/PropertiesList'))),
);
export const PropertiesNewPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/PropertiesNew'))),
  true,
  false,
);
export const PropertiesEditPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/PropertiesEdit'))),
);
export const HomePage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Homepage'))),
);
export const DashboardPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Dashboard'))),
);
export const PricingPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Pricing'))),
);
export const FaqPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Faqs'))),
);
export const TermsPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Terms'))),
);
export const PrivacyPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Privacy'))),
);
export const StartPage = withErrorHandler(
  Loadable(lazy(() => import('../pages/Start'))),
);
