import React from 'react';
import { Container, keyframes, Stack } from '@mui/material';
import useOffSetTop from 'hooks/useOffSetTop';
import { HEADER } from 'config-global';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { MotionContainer, varFade } from 'components/Animate';
import wall2 from 'assets/bg4.png';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  StyledContent,
  StyledRoot,
  StyledSection,
  StyledSectionBg,
} from './styles';
import Header from './components/Header';
import messages from '../../pages/SignIn/messages';
import { TextAnimate } from '../../pages/Faqs/components/faqs-hero';
import { bgGradient } from '../../utils/cssStyles';

const spin = keyframes`
  from {
    transform: translateX(-45rem);
  }
  to {
    transform: translateX(0rem);
  }
`;
const unspin = keyframes`
  from {
    transform: translateX(45rem);
  }
  to {
    transform: translateX(9rem);
  }
`;

const opacitize = keyframes`
  from {

    width: 95%;
  }
  to {

    width: 110%;
  }
`;

export function LoginLayout({
  children,
  illustration,
  title,
  language,
  changeLocale,
  intl,
}) {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <StyledRoot>
      <Header
        white
        isOffset={isOffset}
        language={language}
        changeLocale={changeLocale}
        intl={intl}
      />
      <StyledSection>
        <Container
          sx={{
            height: { md: '100%' },
            py: { xs: 10, md: 0 },
            overflow: 'hidden',
            position: 'relative',
          }}
          style={{ maxWidth: '200%', width: '200%' }}
          component={MotionContainer}
        >
          <Box
            sx={{
              padding: '46vh 30vh 50vh 0vh',
              ...bgGradient({
                color: alpha('#333333', 0.6),
                imgUrl: wall2,
              }),
              animation: `${spin} 200s linear infinite`,
              maxWidth: '150%',
              width: '3000px',
              height: '1080px',
            }}
          >
            <Box
              style={{
                width: '30%',
              }}
              sx={{
                borderRadius: '10px',
                animation: `${unspin} 200s  linear infinite`,
              }}
            >
              <Box
                sx={{
                  borderRadius: '10px',
                  animation: `${opacitize} 1s ease`,
                }}
                style={{
                  width: '110%',
                  background: '#000000ab',
                }}
              >
                <TextAnimate
                  text={intl.formatMessage(messages.title1)}
                  sx={{ color: 'common.white', paddingLeft: '20px' }}
                  variants={varFade({}).inRight}
                />
                <TextAnimate
                  text={intl.formatMessage(messages.title2)}
                  sx={{ color: 'common.white', paddingLeft: '20px' }}
                  variants={varFade({}).inRight}
                />
                <TextAnimate
                  text={intl.formatMessage(messages.title3)}
                  sx={{ color: 'common.white', paddingLeft: '20px' }}
                  variants={varFade({}).inRight}
                />
                <TextAnimate
                  variants={varFade({}).inRight}
                  sx={{ color: '#53a8ff', paddingLeft: '20px' }}
                  text="Revalue"
                />
              </Box>
            </Box>
          </Box>
          <StyledSectionBg />
        </Container>
      </StyledSection>
      <StyledContent style={{ height: '100%', overflowY: 'auto' }}>
        <Stack sx={{ width: 1, marginBottom: '30px' }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}

const mapStateToProps = createStructuredSelector({
  language: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    changeLocale: input => dispatch(changeLocale(input)),
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  injectIntl,
)(LoginLayout);
