import PTIcon from 'assets/portugal.svg';
import ENIcon from 'assets/ic_flag_en.svg';
import React from 'react';
import { PATH_DASHBOARD } from './routes/paths';

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.start;

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const ALL_LANGS = [
  {
    label: 'english',
    value: 'en',
    icon: <img src={ENIcon} alt="en" />,
  },
  {
    label: 'portuguese',
    value: 'pt',
    icon: <img src={PTIcon} alt="pt" />,
  },
];

export const ANALYSIS_CACHE_TIME = 604800000;
export const PAGE_SIZE = 6;

export const DEFAULT_VALUES_NEW_PROPERTY = {
  grossArea: null,
  location: 'Arroios, Lisboa, Lisboa, Portugal',
  usefulArea: null,
  type: 'type.apartment',
  floor: null,
  buildingFloors: null,
  bathrooms: null,
  energyEfficiencyRating: null,
  condition: 'condition.used',
  leaseLeased: false,
  leaseMonthlyRent: null,
  leaseStartDate: null,
  leaseEndDate: null,
  condominiumCosts: null,
  typology: null,
  features: [],
  divisions: [],
  images: [],
  propertyTax: null,
  operatingCosts: null,
  askingPrice: null,
  description: null,
  sourceUrl: null,
  investmentType: 'buyHold',
  // Images default will be handled separately due to JSON.parse
};

export const DEFAULT_VALUES = {
  ANALYSIS_TYPE: 'buyHold',
  MAX_PROPERTIES_PER_ANALYSIS: 60,
  PROPERTY_LOCATION: 'Portugal',
  PROPERTY_CONDITION: 'all',
  PROPERTY_TYPE: 'all',
  PROPERTY_TYPOLOGY: 'all',
  MIN_ASKING_PRICE: null,
  MAX_ASKING_PRICE: null,
  MIN_USEFUL_AREA: null,
  MAX_USEFUL_AREA: null,
  BID_ASK_RATE: 0,
  REAL_ESTATE_TRANSFER_TAX: true,
  ACQUISITION_BROKER: false,
  ACQUISITION_BROKER_RATE: 5,
  ACQUISITION_BROKER_RATE_VAT: 23,
  ACQUISITION_STAMP_DUTY_RATE: 0.8,
  LAND_REGISTRY_INSCRIPTION: 700,
  ACQUISITION_FINANCING: true,
  ACQUISITION_FINANCING_RATE: 80,
  ACQUISITION_INTEREST_RATE: 5.0,
  ACQUISITION_AMORTIZATION: 30,
  ACQUISITION_BANK_COMMISSION_RATE: 1,
  ACQUISITION_LOAN_EARLY_REPAYMENT_RATE: 0.5,
  ACQUISITION_BANK_COMMISSION_STAMP_DUTY_RATE: 4,
  ACQUISITION_MORTGAGE_STAMP_DUTY_RATE: 0.6,
  REHAB: false,
  REHAB_PRICE_PER_SQUARE_METER: 100,
  REHAB_VAT: 23,
  REHAB_FINANCING: false,
  REHAB_FINANCING_RATE: 80,
  REHAB_INTEREST_RATE: 4,
  REHAB_AMORTIZATION: 30,
  REHAB_BANK_COMMISSION_RATE: 1,
  REHAB_LOAN_EARLY_REPAYMENT_RATE: 0.5,
  REHAB_BANK_COMMISSION_STAMP_DUTY_RATE: 4,
  REHAB_MORTGAGE_STAMP_DUTY_RATE: 0.6,
  RENT: true,
  TIME_TO_RENT: 2,
  CONTRACT_PERIOD: 2,
  RENT_HOUSE_PRICE_INDEX_RATE: 2,
  RENT_BROKER: true,
  RENT_BROKER_FEE: 1,
  RENT_BROKER_FEE_VAT: 23,
  PROPERTY_MANAGER: false,
  PROPERTY_MANAGER_RATE: 5,
  PROPERTY_MANAGER_RATE_VAT: 23,
  RENT_TAX_RATE: 25,
  RENT_STAMP_DUTY_RATE: 10,
  PROPERTY_TAX: true,
  CONDOMINIUM_COSTS_PER_SQUARE_METER: 0.3,
  MULTI_RISK_INSURANCE_RATE: 0.1,
  LIFE_INSURANCE_RATE: 0.08,
  MAINTENANCE_COSTS: 15,
  OTHER_OPERATING_COSTS: 0,
  TAX: true,
  TAX_RESIDENT_IN_PORTUGAL: true,
  AGE_LESS_THAN_35: true,
  IRS_CATEGORY: 'irs.category.not.married',
  IRS_CATEGORY_REGION: 'Continente',
  IRS_DEPENDENTS: '0',
  GROSS_SALARY: 1100,
  ACQUISITION_TYPE: 'acquisition.type.investment',
  ACQUISITION_STRUCTURE: 'acquisition.structure.individual',
  DEPRECIATION: false,
  DEPRECIATION_PERIOD: 50,
  CAPITAL_GAINS_TAX_BASE_RATE: 100,
  REHAB_TAX_RATE: 100,
  TIME_TO_SALE: 120,
  PERCENTILE: 'percentile.best.match',
  HOUSE_PRICE_INDEX_RATE: 5,
  EXIT_BROKER: true,
  EXIT_BROKER_RATE: 5,
  EXIT_BROKER_RATE_VAT: 23,
  MIN_PROFIT: null,
  MAX_DEBT_SERVICE_COSTS: null,
  MIN_CAPITAL_REQUIRED: null,
  MIN_INTERNAL_RATE_OF_RETURN: null,
  MAX_REQUIRED_CAPITAL: null,
  ACTIVE_STEP: 0,
  ADVANCE_OPTIONS_STATUS: {
    PROPERTY: false,
    PROPOSAL: false,
    FINANCING: false,
    RENT: false,
    REHAB: false,
    OPERATING_COSTS: false,
    TAX: false,
    SALE: false,
    CAPITAL: false,
  },
  FINANCING: {
    ACQUISITION_FINANCING_RATE_VALUES: {
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 100,
          label: '100%',
        },
      ],
      min: 0,
      max: 100,
      disabled: false,
      unit: '%',
      color: 'primary',
    },
    ACQUISITION_INTEREST_RATE: {
      disabled: false,
      step: 0.1,
      unit: '%',
      min: 0.0,
      max: 10.0,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 10,
          label: '10%',
        },
      ],
    },
    ACQUISITION_AMORTIZATION: {
      disabled: false,
      min: 0,
      max: 40,
      marks: [
        {
          value: 0,
          label: '0',
        },
        {
          value: 40,
          label: '40',
        },
      ],
    },
    ACQUISITION_BANK_COMMISSION_RATE: {
      symbol: <span>%</span>,
    },
  },
  CAPITAL: {
    symbol: <span>&#8364;</span>,
    type: 'number',
    MAX_DEBT_SERVICE_COSTS: {
      symbol: <span>€</span>,
      type: 'number',
    },
    MIN_PROFIT: {
      symbol: <span>€</span>,
      type: 'number',
    },
    MAX_REQUIRED_CAPITAL: {
      symbol: <span>€</span>,
      type: 'number',
    },
    MIN_INTERNAL_RATE_OF_RETURN: {
      symbol: <span>%</span>,
      type: 'number',
    },
    MIN_MULTIPLE_ON_INVESTED_CAPITAL: {
      symbol: <span>x</span>,
      type: 'number',
    },
  },
  AREA: {
    symbol: <span>m²</span>,
  },
  OPERATING_COSTS: {
    PROPERTY_MANAGER: {
      symbol: '%',
      type: 'number',
      unit: '%',
      min: 0,
      max: 100,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 100,
          label: '100%',
        },
      ],
    },
    PROPERTY_MANAGER_VAT: {
      unit: '%',
      step: null,
      disabled: false,
      min: 0,
      max: 23,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 23,
          label: '23%',
        },
      ],
    },
    OTHER_OPERATING_COSTS: {
      symbol: '€',
      type: 'number',
    },
    MAINTENANCE_COSTS: {
      symbol: '€',
      type: 'number',
    },
    LIFE_INSURANCE_RATE: {
      symbol: '%',
      type: 'number',
    },
    LIFE_INSURANCE: {
      symbol: '€',
      type: 'number',
    },
    MULTI_RISK_INSURANCE_RATE: {
      symbol: '%',
      type: 'number',
    },
    MULTI_RISK_INSURANCE: {
      symbol: '€',
      type: 'number',
    },
    CONDOMINIUM_COSTS_PER_SQUARE_METER: {
      symbol: '€/m²',
      type: 'number',
    },
    CONDOMINIUM_COSTS: {
      symbol: '€',
      type: 'number',
    },
  },
  PROPERTY: {
    symbol: <span>&#8364;</span>,
    type: 'number',
    unit: '%',
    min: 0,
    max: 100,
  },
  PROPOSAL: {
    symbol: <span>&#8364;</span>,
    type: 'number',
    unit: '%',
    min: 0,
    max: 100,
    BID_ASK_RATE: {
      min: -20,
      max: 100,
      unit: '%',
      disabled: false,
      marks: [
        {
          value: -20,
          label: '-20%',
        },
        {
          value: 100,
          label: '100%',
        },
      ],
    },
    ACQUISITION_STAMP_DUTY_RATE: {
      min: 0,
      max: 0.8,
      step: null,
      unit: '%',
      disabled: false,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 0.8,
          label: '0.8%',
        },
      ],
    },
    LAND_REGISTRY_INSCRIPTION: {
      symbol: <span>&#8364;</span>,
    },
    ACQUISITION_BROKER: {
      color: 'primary',
    },
    ACQUISITION_BROKER_RATE: {
      min: 0,
      max: 10,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 10,
          label: '10%',
        },
      ],
      step: 0.1,
      unit: '%',
      disabled: false,
    },
    ACQUISITION_BROKER_RATE_VAT: {
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 23,
          label: '23%',
        },
      ],
      unit: '%',
      step: null,
      disabled: false,
      min: 0,
      max: 23,
    },
  },
  REHAB_COMPONENT: {
    color: 'primary',
    REHAB_PRICE_PER_SQUARE_METER: {
      disabled: false,
      min: 0,
      max: 3000,
      unit: '€/m²',
      marks: [
        {
          value: 0,
          label: '0',
        },
        {
          value: 2950,
          label: '3000',
        },
      ],
    },
    REHAB_VAT: {
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 6,
          label: '6%',
        },
        {
          value: 23,
          label: '23%',
        },
      ],
      unit: '%',
      disabled: false,
      min: 0,
      max: 23,
      step: null,
    },
    REHAB_FINANCING_RATE: {
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 100,
          label: '100%',
        },
      ],
      min: 0,
      max: 100,
      unit: '%',
      disabled: false,
    },
    REHAB_INTEREST_RATE: {
      disabled: false,
      step: 0.1,
      unit: '%',
      min: 0.0,
      max: 10.0,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 10,
          label: '10%',
        },
      ],
    },
    REHAB_AMORTIZATION: {
      disabled: false,
      min: 0,
      max: 40,
      marks: [
        {
          value: 0,
          label: '0',
        },
        {
          value: 40,
          label: '40',
        },
      ],
    },
    REHAB_BANK_COMMISSION_RATE: {
      type: 'number',
      symbol: <span>%</span>,
    },
    REHAB_LOAN_EARLY_REPAYMENT_RATE: {
      type: 'number',
      symbol: <span>%</span>,
    },
    REHAB_BANK_COMMISSION_STAMP_DUTY_RATE: {
      type: 'number',
      symbol: <span>%</span>,
    },
    REHAB_MORTGAGE_STAMP_DUTY_RATE: {
      type: 'number',
      symbol: <span>%</span>,
    },
  },
  RENT_COMPONENT: {
    OVERRIDE: {
      type: 'number',
      unit: '€',
    },
    color: 'primary',
    type: 'number',
    disabled: false,
    TIME_TO_RENT: {
      type: 'number',
    },
    CONTRACT_PERIOD: {
      type: 'number',
    },
    RENT_HOUSE_PRICE_INDEX_RATE: {
      unit: '%',
      min: -10,
      max: 10,
      step: 0.1,
      marks: [
        {
          value: -10,
          label: '-10%',
        },
        {
          value: 10,
          label: '10%',
        },
      ],
      disabled: false,
    },
    RENT_BROKER_FEE_VAT: {
      unit: '%',
      step: null,
      disabled: false,
      min: 0,
      max: 23,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 23,
          label: '23%',
        },
      ],
    },
    RENT_STAMP_DUTY_RATE: {
      unit: '%',
      step: null,
      disabled: false,
      min: 0,
      max: 10,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 10,
          label: '10%',
        },
      ],
    },
  },
  SALE: {
    OVERRIDE: {
      symbol: '€',
    },
    TIME_TO_SALE: {
      type: 'number',
      symbol: <span>Month</span>,
      error: null,
    },
    PERCENTILE: {
      error: null,
    },
    HOUSE_PRICE_INDEX_RATE: {
      disabled: false,
      unit: '%',
      step: 0.1,
      min: -10,
      max: 10,
      marks: [
        {
          value: -10,
          label: '-10%',
        },
        {
          value: 10,
          label: '10%',
        },
      ],
      symbol: <span>%</span>,
    },
    EXIT_BROKER_RATE: {
      symbol: <span>%</span>,
    },
    EXIT_BROKER_RATE_VAT: {
      symbol: <span>%</span>,
      min: 0,
      max: 23,
      unit: '%',
      disabled: false,
      step: null,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 23,
          label: '23%',
        },
      ],
    },
  },
  TAX_COMPONENT: {
    TAX: {
      default: true,
    },
    INDIVIDUAL: {
      RENT_TAX: {
        unit: '%',
        disabled: false,
        min: 0,
        step: null,
        max: 25,
        marks: [
          {
            value: 0,
            label: '0%',
          },
          {
            value: 5,
            label: '5%',
          },
          {
            value: 10,
            label: '10%',
          },
          {
            value: 15,
            label: '15%',
          },
          {
            value: 25,
            label: '25%',
          },
        ],
      },
    },
    CAPITAL_GAINS_TAX_BASE_RATE: {
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 50,
          label: '50%',
        },
        {
          value: 100,
          label: '100%',
        },
      ],
      disabled: false,
      unit: '%',
      min: 0,
      max: 100,
    },
    REHAB_TAX_RATE: {
      disabled: false,
      unit: '%',
      min: 0,
      max: 100,
      marks: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 100,
          label: '100%',
        },
      ],
    },
    DEPRECIATION_PERIOD: {
      min: 0,
      max: 50,
      marks: [
        {
          value: 0,
          label: '0',
        },
        {
          value: 50,
          label: '50',
        },
      ],
      disabled: false,
    },
  },
};

export const VALUES_BUY_HOLD = {
  acquisitionAmortization: DEFAULT_VALUES.ACQUISITION_AMORTIZATION,
  acquisitionBankCommissionRate:
    DEFAULT_VALUES.ACQUISITION_BANK_COMMISSION_RATE,
  acquisitionBankCommissionStampDutyRate:
    DEFAULT_VALUES.ACQUISITION_BANK_COMMISSION_STAMP_DUTY_RATE,
  acquisitionBroker: DEFAULT_VALUES.ACQUISITION_BROKER,
  acquisitionBrokerRate: DEFAULT_VALUES.ACQUISITION_BROKER_RATE,
  acquisitionBrokerRateVat: DEFAULT_VALUES.ACQUISITION_BROKER_RATE_VAT,
  acquisitionFinancing: DEFAULT_VALUES.ACQUISITION_FINANCING,
  acquisitionFinancingRate: DEFAULT_VALUES.ACQUISITION_FINANCING_RATE,
  acquisitionInterestRate: DEFAULT_VALUES.ACQUISITION_INTEREST_RATE,
  acquisitionLoanEarlyRepaymentRate:
    DEFAULT_VALUES.ACQUISITION_LOAN_EARLY_REPAYMENT_RATE,
  acquisitionMortgageStampDutyRate:
    DEFAULT_VALUES.ACQUISITION_MORTGAGE_STAMP_DUTY_RATE,
  acquisitionStampDutyRate: DEFAULT_VALUES.ACQUISITION_STAMP_DUTY_RATE,
  acquisitionStructure: DEFAULT_VALUES.ACQUISITION_STRUCTURE,
  acquisitionType: DEFAULT_VALUES.ACQUISITION_TYPE,
  analysisType: DEFAULT_VALUES.ANALYSIS_TYPE,
  bidAskRate: DEFAULT_VALUES.BID_ASK_RATE,
  capitalGainsTaxBaseRate: DEFAULT_VALUES.CAPITAL_GAINS_TAX_BASE_RATE,
  condominiumCostsPerSquareMeter:
    DEFAULT_VALUES.CONDOMINIUM_COSTS_PER_SQUARE_METER,
  contractPeriod: DEFAULT_VALUES.CONTRACT_PERIOD,
  depreciation: DEFAULT_VALUES.DEPRECIATION,
  depreciationPeriod: DEFAULT_VALUES.DEPRECIATION_PERIOD,
  exitBroker: DEFAULT_VALUES.EXIT_BROKER,
  exitBrokerRate: DEFAULT_VALUES.EXIT_BROKER_RATE,
  exitBrokerRateVat: DEFAULT_VALUES.EXIT_BROKER_RATE_VAT,
  grossSalary: DEFAULT_VALUES.GROSS_SALARY,
  ageLessThan35: DEFAULT_VALUES.AGE_LESS_THAN_35,
  housePriceIndexRate: DEFAULT_VALUES.HOUSE_PRICE_INDEX_RATE,
  irsCategory: DEFAULT_VALUES.IRS_CATEGORY,
  irsCategoryRegion: DEFAULT_VALUES.IRS_CATEGORY_REGION,
  irsDependents: DEFAULT_VALUES.IRS_DEPENDENTS,
  landRegistryInscription: DEFAULT_VALUES.LAND_REGISTRY_INSCRIPTION,
  lifeInsuranceRate: DEFAULT_VALUES.LIFE_INSURANCE_RATE,
  maintenanceCosts: DEFAULT_VALUES.MAINTENANCE_COSTS,
  maxAskingPrice: DEFAULT_VALUES.MAX_ASKING_PRICE,
  maxCapitalRequired: DEFAULT_VALUES.MAX_REQUIRED_CAPITAL,
  maxDebtServiceCosts: DEFAULT_VALUES.MAX_DEBT_SERVICE_COSTS,
  maxUsefulArea: DEFAULT_VALUES.MAX_USEFUL_AREA,
  minAskingPrice: DEFAULT_VALUES.MIN_ASKING_PRICE,
  minCapitalRequired: DEFAULT_VALUES.MIN_CAPITAL_REQUIRED,
  minInternalRateOfReturn: DEFAULT_VALUES.MIN_INTERNAL_RATE_OF_RETURN,
  minProfit: DEFAULT_VALUES.MIN_PROFIT,
  minUsefulArea: DEFAULT_VALUES.MIN_USEFUL_AREA,
  multiRiskInsuranceRate: DEFAULT_VALUES.MULTI_RISK_INSURANCE_RATE,
  otherOperatingCosts: DEFAULT_VALUES.OTHER_OPERATING_COSTS,
  percentile: DEFAULT_VALUES.PERCENTILE,
  propertyCondition: DEFAULT_VALUES.PROPERTY_CONDITION,
  propertyLocation: DEFAULT_VALUES.PROPERTY_LOCATION,
  propertyManager: DEFAULT_VALUES.PROPERTY_MANAGER,
  propertyManagerRate: DEFAULT_VALUES.PROPERTY_MANAGER_RATE,
  propertyManagerRateVat: DEFAULT_VALUES.PROPERTY_MANAGER_RATE_VAT,
  propertyTax: DEFAULT_VALUES.PROPERTY_TAX,
  propertyType: DEFAULT_VALUES.PROPERTY_TYPE,
  propertyTypology: DEFAULT_VALUES.PROPERTY_TYPOLOGY,
  realEstateTransferTax: DEFAULT_VALUES.REAL_ESTATE_TRANSFER_TAX,
  rehab: DEFAULT_VALUES.REHAB,
  rehabAmortization: DEFAULT_VALUES.REHAB_AMORTIZATION,
  rehabBankCommissionRate: DEFAULT_VALUES.REHAB_BANK_COMMISSION_RATE,
  rehabBankCommissionStampDutyRate:
    DEFAULT_VALUES.REHAB_BANK_COMMISSION_STAMP_DUTY_RATE,
  rehabFinancing: DEFAULT_VALUES.REHAB_FINANCING,
  rehabFinancingRate: DEFAULT_VALUES.REHAB_FINANCING_RATE,
  rehabInterestRate: DEFAULT_VALUES.REHAB_INTEREST_RATE,
  rehabLoanEarlyRepaymentRate: DEFAULT_VALUES.REHAB_LOAN_EARLY_REPAYMENT_RATE,
  rehabMortgageStampDutyRate: DEFAULT_VALUES.REHAB_MORTGAGE_STAMP_DUTY_RATE,
  rehabPricePerSquareMeter: DEFAULT_VALUES.REHAB_PRICE_PER_SQUARE_METER,
  rehabTaxRate: DEFAULT_VALUES.REHAB_TAX_RATE,
  rehabVat: DEFAULT_VALUES.REHAB_VAT,
  rent: DEFAULT_VALUES.RENT,
  rentBroker: DEFAULT_VALUES.RENT_BROKER,
  rentBrokerFee: DEFAULT_VALUES.RENT_BROKER_FEE,
  rentBrokerFeeVat: DEFAULT_VALUES.RENT_BROKER_FEE_VAT,
  rentHousePriceIndexRate: DEFAULT_VALUES.RENT_HOUSE_PRICE_INDEX_RATE,
  rentStampDutyRate: DEFAULT_VALUES.RENT_STAMP_DUTY_RATE,
  rentTaxRate: DEFAULT_VALUES.RENT_TAX_RATE,
  tax: DEFAULT_VALUES.TAX,
  taxResidentInPortugal: DEFAULT_VALUES.TAX_RESIDENT_IN_PORTUGAL,
  timeToRent: DEFAULT_VALUES.TIME_TO_RENT,
  timeToSale: DEFAULT_VALUES.TIME_TO_SALE,
};

export const VALUES_FIX_FLIP = {
  ...VALUES_BUY_HOLD,
  rent: false,
  timeToSale: 6,
  rehab: true,
  rehabPricePerSquareMeter: 400,
  maintenanceCosts: 0,
};
