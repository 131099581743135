import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Dashboard';

export default defineMessages({
  home: {
    id: `${scope}.home`,
    defaultMessage: 'asd',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'asd',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'asd',
  },
  portuguese: {
    id: `${scope}.portuguese`,
    defaultMessage: 'asd',
  },
  english: {
    id: `${scope}.english`,
    defaultMessage: 'asd',
  },
});
