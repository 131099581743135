import React, { useEffect } from 'react';
import ThemeProvider from 'containers/ThemeProvider';
import Router from 'routes';
import { useRemoveTrailingSlash } from 'hooks/useRemoveTrailingSlash';
import { CheckForIndexHTMLChange } from 'utils/checkForIndexHtmlChange';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export function App(props) {
  useEffect(() => {
    let reloadOnNextChange = false;

    const checkAndSetReload = async () => {
      const { host } = window.location;
      reloadOnNextChange = await CheckForIndexHTMLChange(
        `https://${host}/index.html`,
      );
      if (reloadOnNextChange) {
        console.info('Will reload on next check if necessary.');
      } else {
        console.info('Cache is up to date. No reload necessary.');
      }
    };

    // Run the check initially
    checkAndSetReload();

    // Polling for changes
    const reloadCheckInterval = setInterval(() => {
      if (reloadOnNextChange) {
        console.info(
          "Reloading to get the latest version of the app's index.html",
        );
        caches.keys().then(names => {
          names.forEach(name => {
            caches.delete(name);
          });
        });
        window.localStorage.clear();
        window.location.reload();
        reloadOnNextChange = false;
      } else {
        // Continue to check for changes at the interval
        checkAndSetReload();
      }
    }, 20000); // Check every 60 seconds

    return () => {
      clearInterval(reloadCheckInterval);
    };
  }, []);

  useRemoveTrailingSlash();

  const { intl } = props;
  let widgetId = '';

  if (intl.locale === 'pt') {
    widgetId = process.env.TAWK_WIDGET_PT_ID;
  } else if (intl.locale === 'en') {
    widgetId = process.env.TAWK_WIDGET_EN_ID;
  }

  return (
    <>
      <TawkMessengerReact
        propertyId={process.env.TAWK_PROPERTY_ID}
        widgetId={widgetId}
      />
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </>
  );
}

export default compose(injectIntl)(App);
