/**
 * Handle error either with a default dispatcher or with custom dispatch calls for specific error codes.
 * Use the error code as key for the object, and the function as payload to be executed.
 * @param error
 * @param dispatchers
 */
export const handleError = (error, dispatchers) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (dispatchers[error.response.status]) {
      dispatchers[error.response.status]();
    } else if (dispatchers.default) {
      dispatchers.default();
    }
  } else if (error.request) {
    // The request was made but no response was received
    // eslint-disable-next-line no-console
    console.log(error.request);
    if (dispatchers.default) {
      dispatchers.default();
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    // eslint-disable-next-line no-console
    console.log('Error', error.message);
    if (dispatchers.default) {
      dispatchers.default();
    }
  }
};
