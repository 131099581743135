import { defineMessages } from 'react-intl';

export const scope = 'app.containers.LoginPage';

export default defineMessages({
  title1: {
    id: `${scope}.title1`,
    defaultMessage: 'Hi,',
  },
  title2: {
    id: `${scope}.title2`,
    defaultMessage: 'welcome',
  },
  title3: {
    id: `${scope}.title3`,
    defaultMessage: 'to',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign in to Revalue',
  },
  newUser: {
    id: `${scope}.newUser`,
    defaultMessage: 'New user?',
  },
  createAccount: {
    id: `${scope}.createAccount`,
    defaultMessage: 'Create an account',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: 'Please sign in with your registered Email and Password',
  },
  login: {
    id: `${scope}.button_text`,
    defaultMessage: 'LOGIN',
  },
  forgotPassword: {
    id: `${scope}.forgotPassword`,
    defaultMessage: 'Forgot password?',
  },
  resetHere: {
    id: `${scope}.resetHere`,
    defaultMessage: 'Reset here',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  emailHelperText: {
    id: `${scope}.emailHelperText`,
    defaultMessage: 'You can enter your Email',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  passwordHelper: {
    id: `${scope}.passwordHelper`,
    defaultMessage: 'Your can enter your Password',
  },
  inputRequired: {
    id: `${scope}.inputRequired`,
    defaultMessage: 'Input is required',
  },
  inputValidEmail: {
    id: `${scope}.inputValidEmail`,
    defaultMessage: 'Input must be valid email',
  },
  loginFailed: {
    id: `${scope}.loginFailed`,
    defaultMessage: 'Login Error.',
  },
});
