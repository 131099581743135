import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Homepage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Homepage container!',
  },
  section1title: {
    id: `${scope}.section1title`,
    defaultMessage: 'Analyse Real Estate Investments in Seconds',
  },
  section1subTitle: {
    id: `${scope}.section1subTitle`,
    defaultMessage: 'Unlock investment opportunities for you or your clients',
  },
  section1subTitle2: {
    id: `${scope}.section1subTitle2`,
    defaultMessage: 'TURN MONTHS OF RESEARCH INTO A FEW SECONDS',
  },
  section1button_freeTrial: {
    id: `${scope}.section1button_freeTrial`,
    defaultMessage: 'Free Trial',
  },
  section1button_contactUs: {
    id: `${scope}.section1button_contactUs`,
    defaultMessage: 'Contact Us',
  },
  section1button_contactUs2: {
    id: `${scope}.section1button_contactUs2`,
    defaultMessage: 'Contact Us',
  },
  section1button_login: {
    id: `${scope}.section1button_login`,
    defaultMessage: 'Log In',
  },
  section1button_help: {
    id: `${scope}.section1button_help`,
    defaultMessage: 'Need any Help?',
  },

  section2title1: {
    id: `${scope}.section2title1`,
    defaultMessage: `We provide`,
  },
  section2title2: {
    id: `${scope}.section2title2`,
    defaultMessage: `personalized real estate investment `,
  },
  section2title3: {
    id: `${scope}.section2title3`,
    defaultMessage: `solutions`,
  },
  section2subTitle: {
    id: `${scope}.section2subTitle`,
    defaultMessage:
      'We generate detailed investment analyses based on the criteria you set, such as location, financing conditions, and capital requirements.',
  },

  // tier: {
  //   id: `${scope}.section2.tier`,
  //   defaultMessage: [
  //     {
  //       icon: cent,
  //       title: 'Pay As You Use',
  //       subheader: 'Spend as much as you want, choice is yours',
  //       feature_text: 'FEATURES INCLUDED',
  //       price: '10',
  //       description: ['10 euro = 5 credits', '1 Report = 1 Credit'],
  //       buttonText: 'Free tiral',
  //       buttonVariant: 'contained',
  //     },
  //     {
  //       icon: euro,
  //       title: 'Monthly Subscription',
  //       subheader:
  //         'Subscribe Monthly and enjoy unlimited features and searches',
  //       feature_text: 'FEATURES INCLUDED',
  //       price: '5',
  //       description: ['50 euro/ Month', '5 Report = 1 Credit'],
  //       buttonText: 'Subscribe Monthly',
  //       buttonVariant: 'contained',
  //     },
  //   ],
  // },

  section3title: {
    id: `${scope}.section3title`,
    defaultMessage: `Leverage state-of-the-art technology and distinguish yourself from the competition by providing customizable analysis to your clients.`,
  },
  section3title2: {
    id: `${scope}.section3title2`,
    defaultMessage: `Discover how Revalue can boost your real estate investments and turn your vision into reality`,
  },
  section3contactUs: {
    id: `${scope}.section3contactUs`,
    defaultMessage: `Contact Us`,
  },
  section3freeTrial: {
    id: `${scope}.section3freeTrial`,
    defaultMessage: `Free Trial`,
  },

  // span: {
  //   id: `${scope}.section3.span`,
  //   defaultMessage: `investment forecast`,
  // },
  // subTitle: {
  //   id: `${scope}.section3.subTitle`,
  //   defaultMessage: 'Simulate different assumptions and impact on cash flow',
  // },

  section4title1: {
    id: `${scope}.section4title1`,
    defaultMessage: `Through our advanced technology `,
  },
  section4title2: {
    id: `${scope}.section4title2`,
    defaultMessage: `we generate detailed investment analyses `,
  },
  section4title3: {
    id: `${scope}.section4title3`,
    defaultMessage: `We help you and your clients `,
  },
  section4title4: {
    id: `${scope}.section4title4`,
    defaultMessage: `make informed and successful decisions in the real estate market`,
  },
  section4subTitle: {
    id: `${scope}.section4.subTitle`,
    defaultMessage:
      'Search thousands of portals through our software for your own personal investment assumptions',
  },

  title: {
    id: `${scope}.contactUs.title`,
    defaultMessage: `Contact Us`,
  },
  subTitle: {
    id: `${scope}.contactUs.subTitle`,
    defaultMessage:
      'Contact us so you can know more about our product, we are glad to help.',
  },
  name: {
    id: `${scope}.contactUs.name`,
    defaultMessage: `Name`,
  },
  email: {
    id: `${scope}.contactUs.email`,
    defaultMessage: `Email`,
  },
  phone: {
    id: `${scope}.contactUs.phone`,
    defaultMessage: `Phone Number`,
  },
  company: {
    id: `${scope}.contactUs.company`,
    defaultMessage: `Company`,
  },
  message: {
    id: `${scope}.contactUs.message`,
    defaultMessage: `Enter your message here.`,
  },
  checkBox: {
    id: `${scope}.contactUs.checkBox`,
    defaultMessage: `I agree to receive other communication from Revalue`,
  },
  checkBox1: {
    id: `${scope}.contactUs.checkBox1`,
    defaultMessage: `I agree to allow Revalue to store and process my personal data.`,
  },
  error: {
    id: `${scope}.contactUs.error`,
    defaultMessage: `Please check both boxes`,
  },
  inputRequired: {
    id: `${scope}.inputRequired`,
    defaultMessage: 'Input is required',
  },
  inputValidEmail: {
    id: `${scope}.inputValidEmail`,
    defaultMessage: 'Input must be valid email',
  },
  submit: {
    id: `${scope}.contactUs.submit`,
    defaultMessage: `Submit Now`,
  },
  footerDescription: {
    id: `${scope}.footer.footerDescription`,
    defaultMessage: `Turn 3 months of research into 15 minutes`,
  },
  aboutUs: {
    id: `${scope}.footer.aboutUs`,
    defaultMessage: `About Us`,
  },
  contactUs: {
    id: `${scope}.footer.contactUs`,
    defaultMessage: `Contacte-nos`,
  },
  faq: {
    id: `${scope}.footer.faq`,
    defaultMessage: `FAQs`,
  },
  legal: {
    id: `${scope}.footer.legal`,
    defaultMessage: `Legal`,
  },
  terms: {
    id: `${scope}.footer.terms`,
    defaultMessage: `Terms and Conditions`,
  },
  privacy: {
    id: `${scope}.footer.privacy`,
    defaultMessage: `Privacy Policy`,
  },
  contact: {
    id: `${scope}.footer.contact`,
    defaultMessage: `Contact`,
  },
  rights: {
    id: `${scope}.footer.rights`,
    defaultMessage: `© 2024. All rights reserved`,
  },
  footerEmail: {
    id: `${scope}.footer.email`,
    defaultMessage: `info@revalue.pt`,
  },
  location: {
    id: `${scope}.footer.location`,
    defaultMessage: `Lisbon, Portugal`,
  },
  revalue: {
    id: `${scope}.footer.revalue`,
    defaultMessage: `Revalue`,
  },
  // section6: {
  //   testmonial_title1: {
  //     id: `${scope}.section6.title`,
  //     defaultMessage: `What our`,
  //   },
  //   span: {
  //     id: `${scope}.section6.span`,
  //     defaultMessage: ` customers `,
  //   },
  //   testmonial_title2: {
  //     id: `${scope}.section6.testmonial_title2`,
  //     defaultMessage: `say`,
  //   },
  //   component_text: {
  //     id: `${scope}.section6.component_text`,
  //     defaultMessage: `Get Started now!`,
  //   },
  //   button_signIn: {
  //     id: `${scope}.section6.button_signIn`,
  //     defaultMessage: `Sign In`,
  //   },
  //   button_freeTrial: {
  //     id: `${scope}.section6.button_freeTrial`,
  //     defaultMessage: `Free Trial`,
  //   },
  //   testmonial_data: {
  //     id: `${scope}.section6.testmonial_data`,
  //     defaultMessage: [
  //       {
  //         name: 'Slider1',
  //         cards: [
  //           {
  //             name: 'Alex Martin',
  //             description:
  //               'Search thousands of portals through our software for your own personal investment assumoptions',
  //             logo: 'https://cdn-icons-png.flaticon.com/512/6335/6335489.png',
  //             img: 'https://personalexcellence.co/files/ceo.jpg',
  //             position: 'General Secotary',
  //           },
  //           {
  //             name: 'Andre Mart',
  //             description:
  //               'Search thousands of portals through our software for your own personal investment assumoptions',
  //             logo:
  //               'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Eo_circle_blue_number-2.svg/2048px-Eo_circle_blue_number-2.svg.png',
  //             img:
  //               'https://www.europeanceo.com/wp-content/uploads/2017/08/CEO-magic-touch.jpg',
  //             position: 'Content Creator',
  //           },
  //           {
  //             name: 'Sebastian Gart',
  //             description:
  //               'Search thousands of portals through our software for your own personal investment assumoptions',
  //             logo:
  //               'https://thumbs.dreamstime.com/b/number-big-red-dot-letters-numbers-white-capitalized-font-round-circular-background-up-to-pixels-166634708.jpg',
  //             img:
  //               'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3qf5OPxZeuTif26FQ7qXrKqB4A7Bo39bicg&usqp=CAU',
  //             position: 'General Manager',
  //           },
  //         ],
  //       },
  //       {
  //         name: 'Slider2',
  //         cards: [
  //           {
  //             name: 'John',
  //             description:
  //               'Search thousands of portals through our software for your own personal investment assumoptions',
  //             logo: 'https://cdn-icons-png.flaticon.com/512/6335/6335489.png',
  //             img: 'https://personalexcellence.co/files/ceo.jpg',
  //             position: 'Manager',
  //           },
  //           {
  //             name: 'Jaundry',
  //             description:
  //               'Search thousands of portals through our software for your own personal investment assumoptions',
  //             logo:
  //               'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Eo_circle_blue_number-2.svg/2048px-Eo_circle_blue_number-2.svg.png',
  //             img:
  //               'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3qf5OPxZeuTif26FQ7qXrKqB4A7Bo39bicg&usqp=CAU',
  //             position: 'General Secotary',
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },
  // section7: {
  //   label_fullName: {
  //     id: `${scope}.section7.label_fullName`,
  //     defaultMessage: `Full Name`,
  //   },
  //   label_email: {
  //     id: `${scope}.section7.label_email`,
  //     defaultMessage: `Email Address`,
  //   },
  //   label_company: {
  //     id: `${scope}.section7.label_company`,
  //     defaultMessage: `Company Name`,
  //   },
  //   label_phone: {
  //     id: `${scope}.section7.label_phone`,
  //     defaultMessage: `Phone`,
  //   },
  //   label_message: {
  //     id: `${scope}.section7.label_message`,
  //     defaultMessage: `Message`,
  //   },
  //   button_sendMessage: {
  //     id: `${scope}.section7.button_sendMessage`,
  //     defaultMessage: `Send Message`,
  //   },
  // },
});
