import React from 'react';
import { PATH_DASHBOARD, PATH_PAGE } from 'routes/paths';
import QueryStatsTwoToneIcon from '@mui/icons-material/QueryStatsTwoTone';
import RequestPageTwoToneIcon from '@mui/icons-material/RequestPageTwoTone';
import HomeWorkTwoToneIcon from '@mui/icons-material/HomeWorkTwoTone';
import WindowTwoToneIcon from '@mui/icons-material/WindowTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';

const navConfig = [
  {
    subheader: 'general',
    items: [
      {
        title: 'start',
        path: PATH_DASHBOARD.start,
        icon: <WindowTwoToneIcon />,
      },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'properties',
        path: PATH_DASHBOARD.properties.list,
        icon: <HomeWorkTwoToneIcon />,
      },
      /* {
        title: 'investments',
        path: PATH_DASHBOARD.investments,
        icon: <QueryStatsTwoToneIcon />,
      }, */
      {
        title: 'analysisList',
        path: PATH_DASHBOARD.analysis.list,
        icon: <RequestPageTwoToneIcon />,
      },
      {
        title: 'reports',
        path: PATH_DASHBOARD.reports,
        icon: <SummarizeTwoToneIcon />,
      },
    ],
  },
  {
    items: [
      {
        title: 'faq',
        path: PATH_DASHBOARD.faqs,
        icon: <HelpTwoToneIcon />,
      },
    ],
  },
];

export default navConfig;
