import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Faqs';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Homepage container!',
  },
  section1title: {
    id: `${scope}.section1title`,
    defaultMessage: 'Frequently Asked Questions',
  },
  noHelp: {
    id: `${scope}.noHelp`,
    defaultMessage: "Haven't found the right help?",
  },
  how: {
    id: `${scope}.how`,
    defaultMessage: 'How',
  },
  can: {
    id: `${scope}.can`,
    defaultMessage: 'can',
  },
  we: {
    id: `${scope}.we`,
    defaultMessage: 'we',
  },
  help: {
    id: `${scope}.help`,
    defaultMessage: 'help',
  },
  you: {
    id: `${scope}.you`,
    defaultMessage: 'you?',
  },
  q_1: {
    id: `${scope}.q_1`,
    defaultMessage: 'How can I analyse real estate Investments?',
  },
  a_1: {
    id: `${scope}.a_1`,
    defaultMessage:
      'To analyze a property, click on "My Properties" and select a property.\n' +
      ' To add a property, go to the "My Properties" tab, insert the property listing link, or click "Add New Property" to manually enter the details. Choose between a "Fix and Flip" analysis (buy to sell) or a "Buy to Hold" analysis (buy to rent). \n' +
      'Revalue will automatically generate an analysis, and all fields can be customized to suit your profile.  Easily adjust all investment variables within the Portfolio Analyzer using the Professional Mode in the top right corner or directly in the sidebar during an analysis.\n',
  },
  q_2: {
    id: `${scope}.q_2`,
    defaultMessage: 'How do you calculate the property acquisition price?',
  },
  a_2: {
    id: `${scope}.a_2`,
    defaultMessage:
      'The current value is calculated based on comparable properties at the parish level, adjusted for the specific condition and attributes. The acquisition price in the "Portfolio Analyser" is determined by applying a "Discount Rate" to the listing price. You can adjust this rate by activating professional mode and changing the "Discount Rate" in the "Proposal" section, or within an analysis in the sidebar under “Proposal/ Use Custom Acquisition Value”.',
  },
  q_3: {
    id: `${scope}.q_3`,
    defaultMessage: 'How is the current property value calculated?',
  },
  a_3: {
    id: `${scope}.a_3`,
    defaultMessage:
      'The current value is calculated based on comparable properties at the parish level, adjusted for the specific condition and attributes. This value can be modified in the "Portfolio Analyser" by adjusting the "Discount Rate" in the "Proposal" section, or within an analysis in the sidebar under “Proposal/ Use Custom Acquisition Value”.',
  },
  q_4: {
    id: `${scope}.q_4`,
    defaultMessage: 'How is the property sale value calculated?',
  },
  a_4: {
    id: `${scope}.a_4`,
    defaultMessage:
      'The property sale value is determined by applying an annual "Market Evolution" rate to the current property value. This rate can be adjusted in both the portfolio analyser and within an analysis in the sidebar under "Sale/Use Custom Property Value."',
  },
  q_5: {
    id: `${scope}.q_5`,
    defaultMessage: 'I want to finance the acquisition; how can I do it?',
  },
  a_5: {
    id: `${scope}.a_5`,
    defaultMessage:
      'In the "Financing" section, you can decide whether to finance the property acquisition. If you choose to finance, you can adjust parameters like the "Financing Rate," "Interest Rate," "Amortization Period," "Bank Commission," "Early Repayment Commission," "Stamp Duty on Bank Commission," and "Stamp Duty on Financing." These adjustments can be made in both the "Portfolio Analyser" and within an analysis in the sidebar under "Financing."',
  },
  q_6: {
    id: `${scope}.q_6`,
    defaultMessage: 'I want to finance rehabilitation; how can I do it?',
  },
  a_6: {
    id: `${scope}.a_6`,
    defaultMessage:
      'In the "Property Rehabilitation" section, you can choose whether to finance property rehabilitation. This can be adjusted in the "Portfolio Analyser" or directly in the sidebar during an analysis under “Property Rehabilitation/ Are you going to finance property rehabilitation?”.',
  },
  q_7: {
    id: `${scope}.q_7`,
    defaultMessage:
      'I want to lease the property; how can I define the lease period?',
  },
  a_7: {
    id: `${scope}.a_7`,
    defaultMessage:
      'In the sidebar under "Rent," you can customize all leasing-related variables, such as monthly rent, lease duration, real estate agency fees, and average time to rent. These adjustments can be made both in the Portfolio Analyser and within an analysis under "Rent/Time to Rent."',
  },
  q_8: {
    id: `${scope}.q_8`,
    defaultMessage: 'Can I change the rent suggested by Revalue?',
  },
  a_8: {
    id: `${scope}.a_8`,
    defaultMessage:
      'Yes. The rent estimated by Revalue is calculated based on comparables at the parish level, adjusted for the property\'s condition and attributes. This value can be adjusted in both the "Portfolio Analyser" and within an analysis in the sidebar under "Rent/Use Custom Rent Value."',
  },
  q_9: {
    id: `${scope}.q_9`,
    defaultMessage:
      'How can I define operational costs such as insurance and condominium fees?',
  },
  a_9: {
    id: `${scope}.a_9`,
    defaultMessage:
      'All operational costs, such as "Condominium Fees," "Municipal Property Tax," "Multi-risk Insurance," "Life Insurance," "Maintenance Costs," and "Other Monthly Operational Costs," can be adjusted in both the "Portfolio Analyser" and within an analysis in the sidebar under "Operating Costs."',
  },
  q_10: {
    id: `${scope}.q_10`,
    defaultMessage: 'How can I define my tax conditions?',
  },
  a_10: {
    id: `${scope}.a_10`,
    defaultMessage:
      'You can change the "Acquisition Structure" between "Individual" or "Company" and the "Purpose of Acquisition" for Real Estate Transfer Tax calculation. You can also adjust the basis for "Capital Gains Tax" and the "Capitalization Rate for Renovations." These settings can be adjusted in both the "Portfolio Analyser" and within an analysis in the sidebar under "Taxes."',
  },
  q_11: {
    id: `${scope}.q_11`,
    defaultMessage: 'Why do I have to choose a period for my investment?',
  },
  a_11: {
    id: `${scope}.a_11`,
    defaultMessage:
      'An investment should always be analysed with an entry and exit date to properly assess the annualized return after all taxes and costs associated with the property sale. This period can be adjusted in both the "Portfolio Analyser" and within an analysis in the sidebar under "Sale/Time to Sale."',
  },
  q_12: {
    id: `${scope}.q_12`,
    defaultMessage: 'Can I perform multiple analyses for the same property?',
  },
  a_12: {
    id: `${scope}.a_12`,
    defaultMessage:
      'Yes, with Revalue, you can simulate various scenarios and estimate returns for each situation. For instance, you can analyse the impact of property renovation costs being 20% higher than initially estimated. By adjusting the renovation cost from €1000 per square meter to €1200 per square meter, you can assess the return in each scenario.\n' +
      "To adjust assumptions, click the “Edit” button to open the sidebar. Once you've made your adjustments, click “Analyse” to open a new tab, enabling a side-by-side comparison of both analyses. This feature allows for a thorough comparison of different scenarios, helping you make informed decisions.\n",
  },
  q_13: {
    id: `${scope}.q_13`,
    defaultMessage:
      'The information in the listing is incorrect; how can I change it?',
  },
  a_13: {
    id: `${scope}.a_13`,
    defaultMessage:
      'With Revalue, you can keep your property information up-to-date as new data becomes available. In the “Portfolio Analyser,” you have a list of properties in your database. This allows you to analyse properties or edit their details before conducting an analysis, ensuring all information is current and accurate.\n' +
      'During an analysis, you can make updates by clicking on the “Edit Property” button located in the top right corner, above the property image. This feature allows you to refine your analysis with the latest property data.\n' +
      "Note: Editing property details may impact the property's valuation.\n",
  },
  q_14: {
    id: `${scope}.q_14`,
    defaultMessage: 'I want to share the report; how can I do it?',
  },
  a_14: {
    id: `${scope}.a_14`,
    defaultMessage:
      'To share a professional report with clients, go into the analysis and click on the “Share” button. Copy the provided link to share the report. If you need to revoke access later, you can easily do so by selecting “Stop Sharing.”\n' +
      'In the sidebar, under “Reports,” you can view all your active reports, making it easy to manage and track shared documents.\n',
  },
  q_15: {
    id: `${scope}.q_15`,
    defaultMessage: 'Which actions consume credits?',
  },
  a_15: {
    id: `${scope}.a_15`,
    defaultMessage:
      'The use of credits is necessary for all analysis actions:\n' +
      '1.\tPortfolio Analysis\n' +
      '2.\tAnalysis of an existing property\n' +
      '3.\tEditing an existing analysis\n',
  },
  q_16: {
    id: `${scope}.q_16`,
    defaultMessage: 'How can i manage my subscription?',
  },
  a_16: {
    id: `${scope}.a_16`,
    defaultMessage:
      'In the top right corner, click on your profile, then go to "Account and Billing." In this section, you can purchase credits or subscribe to a monthly or yearly plan.',
  },
  q_17: {
    id: `${scope}.q_17`,
    defaultMessage: 'I want to share the report; how can I do it?',
  },
  a_17: {
    id: `${scope}.a_17`,
    defaultMessage:
      'To share a professional report with clients, click "Share" and copy the link. If you no longer want your client to have access later on, you can "Stop Sharing" and revoke access to the report.',
  },
});
