import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { PATH_PAGE } from 'routes/paths';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from '../../assets/logo.png';
import messages from '../../pages/Homepage/messages';

const LINKS = [
  {
    headline: 'revalue',
    children: [
      { name: 'contactUs', href: PATH_PAGE.contact },
      { name: 'faq', href: PATH_PAGE.faqs },
    ],
  },
  {
    headline: 'legal',
    children: [
      { name: 'terms', href: PATH_PAGE.terms },
      { name: 'privacy', href: PATH_PAGE.privacy },
    ],
  },
  {
    headline: 'contact',
    children: [
      { name: 'footerEmail', href: 'mailto:info@revalue.pt' },
      { name: 'location', href: '' },
    ],
  },
];

export default function Footer({ intl }) {
  const theme = useTheme();

  const islargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        paddingTop: '0px',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container style={{ paddingTop: '20px' }} sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid
            style={{ margin: islargeScreen ? '' : '0rem 5.5rem' }}
            item
            xs={12}
            sx={{ mb: 3 }}
          >
            <img width="200px" src={Logo} alt="logo" />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {intl.formatMessage({ ...messages.footerDescription })}
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
              {/*  {_socials.map(social => (
                <IconButton key={social.name}>
                  <Iconify icon={social.icon} />
                </IconButton>
              ))} */}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              {LINKS.map(list => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {intl.formatMessage({ ...messages[list.headline] })}
                  </Typography>

                  {list.children.map(link => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      to={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {intl.formatMessage({ ...messages[link.name] })}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {intl.formatMessage({ ...messages.rights })}
        </Typography>
      </Container>
    </Box>
  );

  return mainFooter;
}
