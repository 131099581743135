import React, { useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Button, Container, Link, Toolbar } from '@mui/material';
import useOffSetTop from 'hooks/useOffSetTop';
import { bgBlur } from 'utils/cssStyles';
import { HEADER } from 'config-global';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_PAGE } from 'routes/paths';
import LanguagePopover from 'containers/LanguageProvider/components/LanguagePopover';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from 'components/Logo';
import Label from 'components/Label';
import messages from '../../pages/Homepage/messages';

export default function Header({ language, changeLocale, intl, logged }) {
  const carouselRef = useRef(null);
  const navigate = useNavigate();

  const theme = useTheme();

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  const islargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <AppBar
      ref={carouselRef}
      color="transparent"
      sx={{ boxShadow: 0, maxWidth: 'none' }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          boxShadow: `0px 9px 17px -9px rgba(0,0,0,0.1)`,
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container
          style={{ maxWidth: 'none' }}
          sx={{
            height: 1,
            display: 'flex',
            alignItems: 'center',
            marginRight: '40px',
          }}
        >
          {!logged && (
            <Logo
              withText
              w={islargeScreen ? 165 : 40}
              h={islargeScreen ? 100 : 40}
            />
          )}
          <Box sx={{ flexGrow: 1 }} />
          {!logged && (
            <Link
              onClick={() => navigate(PATH_PAGE.faqs)}
              target="_blank"
              rel="noopener"
              sx={{ ml: 1 }}
            >
              <Label sx={{ cursor: 'pointer' }}>FAQ's</Label>
            </Link>
          )}
          {!logged && (
            <Button
              style={{
                borderRadius: '50px',
                minWidth: '120px',
                textWrap: 'nowrap',
                marginLeft: '2rem',
              }}
              size={islargeScreen ? '' : 'small'}
              variant="contained"
              target="_self"
              rel="noopener"
              onClick={() => navigate(PATH_PAGE.contact)}
            >
              {intl.formatMessage({
                ...messages.section1button_contactUs,
              })}
            </Button>
          )}
          {!logged && (
            <Button
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                borderRadius: '50px',
                textWrap: 'nowrap',
              }}
              size={islargeScreen ? '' : 'small'}
              variant="outlined"
              target="_blank"
              rel="noopener"
              onClick={() => navigate(PATH_AUTH.signIn)}
            >
              {intl.formatMessage({
                ...messages.section1button_login,
              })}
            </Button>
          )}
          <LanguagePopover
            language={language}
            changeLocale={changeLocale}
            intl={intl}
          />
        </Container>
      </Toolbar>
      {isOffset && <Shadow />}
    </AppBar>
  );
}

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: theme => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
