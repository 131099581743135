import { ApiClient } from 'apiClients/index';
import {
  GET_CREDITS_BY_USERNAME_PATH,
  GET_USER_BY_USERNAME_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  NEW_USER_PATH,
  UPDATE_USER_PATH,
} from 'apiClients/PropertyManager/constants';

export const loginUser = args => {
  const propertyManagerPublicClient = new ApiClient('PROPERTY_MANAGER_PUBLIC');
  return propertyManagerPublicClient.post(LOGIN_PATH, { ...args });
};

export const createUser = args => {
  const propertyManagerClient = new ApiClient('PROPERTY_MANAGER');
  return propertyManagerClient.post(NEW_USER_PATH, { ...args });
};

export const getUserByUsername = () => {
  const propertyManagerClient = new ApiClient('PROPERTY_MANAGER');
  return propertyManagerClient.get(`${GET_USER_BY_USERNAME_PATH}`);
};

export const getCreditsByUsername = () => {
  const propertyManagerClient = new ApiClient('PROPERTY_MANAGER');
  return propertyManagerClient.get(`${GET_CREDITS_BY_USERNAME_PATH}`);
};

export const logout = args => {
  const propertyManagerClient = new ApiClient('PROPERTY_MANAGER');
  return propertyManagerClient.post(LOGOUT_PATH, { ...args });
};

export const updateUser = inputsVar => {
  const formData = new FormData();

  // Append each input individually to the FormData
  // eslint-disable-next-line no-restricted-syntax
  for (const key in inputsVar) {
    if (key === 'logoUrl' && inputsVar[key] !== null) {
      // Handle the logoUrl separately
      const file = inputsVar[key];
      if (typeof file !== 'string') {
        formData.append('logo', file);
      }
    } else if (inputsVar[key] !== null) {
      formData.append(key, inputsVar[key]);
    }
  }
  const propertyManagerApiClient = new ApiClient('PROPERTY_MANAGER');
  return propertyManagerApiClient.put(UPDATE_USER_PATH, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
