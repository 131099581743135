import {
  CREATE_CUSTOMER_SESSION,
  CREATE_CUSTOMER_SESSION_SUCCESS,
} from './constants';
import { ApiClient } from '../../apiClients';
import { CREATE_CUSTOMER_SESSION_PATH } from '../../apiClients/PropertyManager/constants';

export const getCustomerSession = () => async dispatch => {
  dispatch({
    type: CREATE_CUSTOMER_SESSION,
  });
  const propertyManagerApiClient = new ApiClient('PROPERTY_MANAGER', dispatch);
  await propertyManagerApiClient
    .post(CREATE_CUSTOMER_SESSION_PATH)
    .then(response => {
      dispatch({
        type: CREATE_CUSTOMER_SESSION_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};
